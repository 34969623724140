function initializeCategoryFilter(categoryFilterEl:HTMLDivElement) {
  const filterOptions: NodeListOf<HTMLAnchorElement> = categoryFilterEl.querySelectorAll('.filter-option');
  const filterItems: NodeListOf<HTMLDivElement> = categoryFilterEl.querySelectorAll('.filter-item');
  const dropdownButtonText = <HTMLButtonElement>categoryFilterEl.querySelector('.dropdown .button-text');
  const dropdownSelect = <HTMLSelectElement>categoryFilterEl.querySelector('.dropdown select');
  const horizontalDragger = <HTMLSelectElement>categoryFilterEl.querySelector('.horizontal-dragger');

  const activeClass = 'active';
  const hiddenClass = 'hidden';

  function updateDropdown(passedFilterOption: HTMLAnchorElement) {
    if (dropdownSelect && dropdownButtonText) {
      dropdownSelect.value = passedFilterOption.dataset.filter || '';
      dropdownButtonText.innerHTML = passedFilterOption.innerHTML || '';
    }
  }

  function setFilterOptionActive(passedFilterOption: HTMLAnchorElement) {
    if (filterOptions) {
      filterOptions.forEach((filterOption) => {
        if (passedFilterOption === filterOption) {
          filterOption.classList.add(activeClass);
        } else {
          filterOption.classList.remove(activeClass);
        }
      });
    }
  }

  function performFilterOfItems(passedFilterOption: HTMLAnchorElement) {
    if (filterItems) {
      filterItems.forEach((filterItem) => {
        if (filterItem.dataset.filter && passedFilterOption.dataset.filter) {
          if (filterItem.dataset.filter.toLowerCase().replace(/\s/g, '') === passedFilterOption.dataset.filter.toLowerCase().replace(/\s/g, '')) {
            filterItem.classList.remove(hiddenClass);
          } else {
            filterItem.classList.add(hiddenClass);
          }
        }
      });
    }
  }

  function getEquivalentFilterOption() {
    if (dropdownSelect && filterOptions) {
      let returnfilterOption = <HTMLAnchorElement>filterOptions[0];

      filterOptions.forEach((filterOption) => {
        if (dropdownSelect.value === filterOption.dataset.filter) {
          returnfilterOption = filterOption;
        }
      });

      return returnfilterOption;
    }
  }

  function updateHorizontalDragger() {
    if (horizontalDragger) {
      const event = new CustomEvent('UPDATE_SWIPER');
      horizontalDragger.dispatchEvent(event);
    }
  }

  function performFiltering(filterOption: HTMLAnchorElement) {
    setFilterOptionActive(filterOption);
    performFilterOfItems(filterOption);
    updateDropdown(filterOption);
    updateHorizontalDragger();
  }

  function addEventListeners() {
    if (filterOptions) {
      filterOptions.forEach((filterOption) => {
        filterOption.addEventListener('click', (e) => {
          e.preventDefault();
          performFiltering(filterOption);
        });
      });
    }

    if (dropdownSelect) {
      dropdownSelect.addEventListener('change', (e) => {
        e.preventDefault();

        const filterOption = getEquivalentFilterOption();

        if (filterOption) {
          performFiltering(filterOption);
        }
      });
    }
  }

  addEventListeners();
}

export function initializeCategoryFilters() {
  const categoryFilers: NodeListOf<HTMLDivElement> = document.querySelectorAll('.category-filter');
  categoryFilers.forEach(initializeCategoryFilter);
}
