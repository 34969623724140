import truncate from 'lodash/truncate';
import Swiper, { Mousewheel, Scrollbar } from 'swiper';
import breakpoints from '../constants/breakpoints';

Swiper.use([Mousewheel, Scrollbar]);

export function initializeHorizontalDragger(containerEl:HTMLDivElement) {
  const slides: NodeListOf<HTMLDivElement> = containerEl.querySelectorAll('.swiper-slide');
  const scrollbar = <HTMLDivElement>containerEl.querySelector('.swiper-scrollbar');
  const draggingClass = "swiper-scrollbar-dragging";

  let swiper: Swiper | null;

  const events = {
    scrollbarDragStart: function() {},
    scrollbarDragEnd: function() {}
  };
  
  let breakpointOptions = {};

  if (containerEl.dataset.desktopSmallMinSlides) {
    const minSlides = parseInt(containerEl.dataset.desktopSmallMinSlides);

    breakpointOptions = {
      [breakpoints.desktop_small]: {
        allowTouchMove: slides.length >= minSlides
      }
    };
  }

  let scrollbarOptions = {};

  if (scrollbar) {
    scrollbarOptions = {
      el: '.swiper-scrollbar',
      draggable: true
    };

    events.scrollbarDragStart = function() {
      containerEl.classList.add(draggingClass);
    };

    events.scrollbarDragEnd = function() {
      containerEl.classList.remove(draggingClass);
    }
  }

  function initSwiper() {
    swiper = new Swiper(containerEl, {
      breakpoints: breakpointOptions,
      freeMode: true,
      mousewheel: {
        forceToAxis: true
      },
      observer: true,
      on: events,
      scrollbar: scrollbarOptions,
      slidesPerView: 'auto'
    });

    // Seems scrollbar handle doesn't always update to reflect new size of inner,
    // so we'll force an update ourselves (only necessary if there is a scrollbar)
    if(scrollbar) {
      swiper?.on('observerUpdate', function() {
        swiper?.scrollbar.updateSize();
      });
    }

    containerEl.addEventListener('UPDATE_SWIPER', () => {
      if (swiper) {
        swiper.update();
        
        // Bit of a hack.
        // Send a window resize event to ensure the swiper is
        // correctly reinitialising.
        // Was having an issue with a non-scrollbar state being
        // updated to a with-scrollbar state.
        // Scrollbar was appearing but no dragger was present.
        const event = new CustomEvent('resize');
        window.dispatchEvent(event);
      }
    });

  }

  initSwiper();
}
  
export function initializeHorizontalDraggers() {
  const horizontalDraggers: NodeListOf<HTMLDivElement> = document.querySelectorAll('.horizontal-dragger');
  horizontalDraggers.forEach(initializeHorizontalDragger);
}
