import { offset } from '../utils/offset';
import { registerScrollListener } from '../utils/scroll';

const initializeSidebarLayout = () => {
  const sidebarSections = document.querySelectorAll<HTMLElement>(
    'section.sidebar-layout'
  );
  sidebarSections.forEach((section) => {
    const sidebar = section.querySelector<HTMLDivElement>('.sidebar');
    const navList = sidebar?.querySelector('ul');
    if (!sidebar || !navList) return;

    // Set top value for the sticky sidebar
    let sidebarOffset = offset(sidebar);
    const onResize = () => {
      sidebarOffset = offset(sidebar);
      navList.style.top = `${sidebarOffset.top}px`;
    };
    window.addEventListener('resize', onResize);
    onResize();

    // Provide scroll animation for anchor links
    const anchorLinks = Array.from(
      navList.querySelectorAll<HTMLAnchorElement>('a[href^="#"]')
    );
    const anchorTargets = anchorLinks.map((link) => {
      const selector = link.getAttribute('href')!;
      return document.querySelector<HTMLElement>(selector);
    });

    const setActiveAnchorLink = (activeIndex: number) => {
      anchorLinks.forEach((link, index) =>
        link.classList.toggle('active', index === activeIndex)
      );
    };

    // Determine which sidebar item is active
    const onScroll = () => {
      const scrollPosition = document.documentElement.scrollTop;
      let activeIndex = 0;
      for (let index = 0; index < anchorTargets.length; index += 1) {
        const target = anchorTargets[index];
        if (
          target &&
          scrollPosition >= offset(target).top - sidebarOffset.top
        ) {
          activeIndex = index;
        }
      }
      setActiveAnchorLink(activeIndex);
    };

    registerScrollListener(onScroll);
    onScroll();
  });
};

export { initializeSidebarLayout };
