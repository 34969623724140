import { idText } from 'typescript';
import { IApp } from '../../apps/interfaces';

let modalOverlay: HTMLDivElement|null;
let appObj: IApp;


export const openGetStartedModal = (officeStyle?: string|undefined, stepId?: string|undefined) => {
  import('../../apps/signup_chat').then((app) => {
    modalOverlay = document.body.querySelector<HTMLDivElement>('#get_started_modal_root');
    appObj = app;

    if(modalOverlay) {
      const appRoot = modalOverlay.querySelector<HTMLDivElement>('#get_started_app_root');
      const closeButton:HTMLButtonElement|null = modalOverlay.querySelector<HTMLButtonElement>('.close-button');

      document.body.classList.add('noscroll');
      modalOverlay?.classList.add('visible');
      closeButton?.addEventListener('click', closeGetStartedModal);

      appRoot && app.render(appRoot, closeGetStartedModal, officeStyle, stepId);
    }
  });
};

const closeGetStartedModal = () => {
  const modalOverlay:HTMLDivElement|null = document.body.querySelector<HTMLDivElement>('#get_started_modal_root');
  const body = document.querySelector('body');

  body?.classList.remove('noscroll');
  modalOverlay?.classList.remove('visible');

  appObj.unmount();
} 