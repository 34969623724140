function initializeImageGallery(gallery: HTMLDivElement) {
  const outer = gallery.querySelector<HTMLDivElement>('.js-image-gallery-outer');
  const inner = gallery.querySelector<HTMLDivElement>('.js-image-gallery-inner');
  const images = inner?.querySelectorAll('img');
  if (!(outer && inner && images)) {
    return;
  }

  // Remove text nodes
  inner.childNodes.forEach(function (child) {
    if (child.nodeType == Node.TEXT_NODE) {
      inner.removeChild(child);
    }
  });

  let isDragging = false;
  let dragStartX = 0;
  let offsetX = 0;
  let targetOffsetX = 0;
  let scrollSpeed = 0.5; // Pixels to move per animation frame

  function getElementWidth(element: HTMLDivElement): number {
    const style = getComputedStyle(element);
    const width =
      element.clientWidth +
      parseFloat(style.marginLeft) +
      parseFloat(style.marginRight);
    return width;
  }

  function update() {
    if (!inner || inner.childNodes.length === 0) {
      return;
    }
    while (offsetX > 0) {
      const lastChildWidth = getElementWidth(inner.lastChild as HTMLDivElement);
      offsetX -= lastChildWidth;
      targetOffsetX -= lastChildWidth;
      dragStartX += lastChildWidth;
      if (inner.lastChild) {
        inner.insertBefore(inner.lastChild, inner.firstChild);
      }
    }
    let firstChildWidth = getElementWidth(inner.firstChild as HTMLDivElement);

    while (offsetX < -firstChildWidth) {
      offsetX += firstChildWidth;
      targetOffsetX += firstChildWidth;
      dragStartX -= firstChildWidth;
      if (inner.firstChild) {
        inner.appendChild(inner.firstChild);
        firstChildWidth = getElementWidth(inner.firstChild as HTMLDivElement);
      }
    }
    inner.style.transform = 'translateX(' + offsetX + 'px)';
  }

  function tick() {
    requestAnimationFrame(tick);
    if (!isDragging) {
      targetOffsetX -= scrollSpeed;
    }
    offsetX += (targetOffsetX - offsetX) * 0.1;
    update();
  }

  function onResize() {
    if (outer && inner) {
      outer.style.height = inner.clientHeight + 'px';
    }
  }

  window.addEventListener('resize', onResize);

  images.forEach(function (image) {
    image.addEventListener('load', onResize);
  });

  setTimeout(function () {
    onResize();
    tick();
  }, 200);

  function onInteractionStart(event: UIEvent, pageX: number) {
    event.preventDefault();
    dragStartX = pageX - offsetX;
    gallery.style.cursor = 'grabbing';
    isDragging = true;
  }

  function onInteractionEnd() {
    gallery.style.cursor = 'grab';
    isDragging = false;
  }

  function onInteractionMove(pageX: number) {
    if (!isDragging) {
      return false;
    }
    targetOffsetX = pageX - dragStartX;
  }

  gallery.addEventListener('mousedown', function (event) {
    if (event.which === 1) {
      onInteractionStart(event, event.pageX);
    }
  });
  gallery.addEventListener('touchstart', function (event) {
    if (event.touches.length === 1) {
      onInteractionStart(event, event.touches[0].pageX);
    }
  });

  document.addEventListener('mouseup', onInteractionEnd);
  document.addEventListener('touchend', onInteractionEnd);
  document.addEventListener('touchcancel', onInteractionEnd);

  document.addEventListener('mousemove', (e) => onInteractionMove(e.pageX));
  document.addEventListener('touchmove', (e) =>
    onInteractionMove(e.touches[0].pageX)
  );
}

export function initializeImageGalleries() {
  const galleries: NodeListOf<HTMLDivElement> =
    document.querySelectorAll('.js-image-gallery');
  galleries.forEach(initializeImageGallery);
}
