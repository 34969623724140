import { gsap, Power1, Power3, Sine } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const initializeUspCircleAnimation = (container: HTMLDivElement) => {
  const usp = container;

  const transferDuration = 0.4;
  const waitDuration = 2;

  const innerDotFade = { duration: 0.2, fill: '#bebebe' };
  const innerDotOpaque = { duration: 0.2, fill: '#1E37A0' };

  const outerDotOff = { duration: 2, scale: 1 / 3, ease: Power3.easeIn };
  const outerDotOn = {
    duration: transferDuration,
    scale: 1,
    ease: Power1.easeIn,
  };

  const textPre = (index: number) => {
    return {
      duration: 0,
      opacity: 0,
      y: 20,
    };
  };
  const textOn = {
    duration: 0.6,
    ease: Sine.easeOut,
    opacity: 1,
    y: 0,
  };
  const textPost = (index: number) => {
    return {
      duration: waitDuration,
      opacity: 0,
      ease: Power3.easeIn,
    };
  };

  const strokePre = { duration: 0, strokeDasharray: '0.00001 1256.6' };
  const strokeOn = {
    duration: waitDuration,
    strokeDasharray: '284 1256.6',
    ease: Power1.easeIn,
  };
  const strokePost = (index: number) => {
    return {
      duration: transferDuration,
      strokeDasharray: '0.00001 1256.6',
      attr: {
        'stroke-dashoffset': ['-1226.5', '-284', '-598.2', '-912.3'][index],
      },
      ease: Power1.easeIn,
    };
  };

  const arrowOff = {
    duration: 0.3,
    strokeDasharray: '0.00001 26.8',
    attr: { 'stroke-dashoffset': '13.4' },
    ease: Power1.easeIn,
  };
  const arrowOn = {
    duration: 0.1,
    strokeDasharray: '26.8 26.8',
    attr: { 'stroke-dashoffset': '53.7' },
    ease: Power1.easeOut,
  };

  function segment(index: number): gsap.core.Timeline {
    const int = `${index}`;
    const stroke = usp.querySelector('.stroke-' + int) as HTMLDivElement;
    const outer = usp.querySelector('.dot-outer-' + int) as HTMLDivElement;
    const inner = usp.querySelector('.dot-inner-' + int) as HTMLDivElement;
    const arrow = usp.querySelector('.arrow-' + int) as HTMLDivElement;
    const text = usp.querySelector('.text-' + int) as HTMLDivElement;

    return (
      gsap
        .timeline({ defaults: { ease: 'none' } })
        // Dot + text
        .fromTo(inner, innerDotFade, innerDotOpaque)
        .fromTo(outer, outerDotOff, outerDotOn, '-=0.2') // time with line shrink of previous
        .fromTo(text, textPre(index), textOn)
        // Grow line
        .fromTo(stroke, strokePre, strokeOn)
        .to(outer, outerDotOff, '<')
        .to(text, textPost(index), '<')
        // Show arrow
        .fromTo(arrow, arrowOff, arrowOn)
        // Shrink line, hide arrow, empty dot
        .to(stroke, strokePost(index), '<')
        .to(inner, innerDotFade, '<')
        .to(arrow, arrowOff, '-=0.2')
    );
  }

  const tl = gsap.timeline({ repeat: -1, paused: true });
  const offset = '-=0.6';
  tl.add(segment(0), offset)
    .add(segment(1), offset)
    .add(segment(2), offset)
    .add(segment(3), offset);

  setTimeout(() => {
    ScrollTrigger.create({
      trigger: usp,
      start: 'top bottom',
      end: 'bottom top',
      invalidateOnRefresh: true,
      onToggle: (self) => {
        if (self.isActive) {
          tl.resume();
        } else {
          tl.pause();
        }
      },
    });
  }, 500);
};

export const initializeUspCircle = () => {
  const circles: NodeListOf<HTMLDivElement> = document.querySelectorAll(
    '.usp-circle'
  );
  circles.forEach(initializeUspCircleAnimation);
};
