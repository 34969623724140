import get from 'lodash/get';

const FILE_INPUT_PLACEHOLDER_TEXT = 'Attach floorplan';
const signupSectionErrorClass = 'signup-section__form--error-state';
const signupInputErrorClass = 'signup-section__input--error-state';

function initializeSignupForm2(signupSection: HTMLDivElement) {
  const sizeInputEl = signupSection.querySelector<HTMLInputElement>(
    '#size-input'
  );
  const sizeInputMeasureEl = signupSection.querySelector<HTMLSpanElement>(
    '#size-input-measure'
  );
  const emailInputEl = signupSection.querySelector<HTMLInputElement>(
    '#email-input'
  );
  const labelEl = signupSection.querySelector<HTMLParagraphElement>(
    '#file-label'
  );
  const fileInputEl = signupSection.querySelector<HTMLInputElement>(
    '#file-input'
  );
  const submitButton = signupSection.querySelector<HTMLButtonElement>(
    '.js-contact-form-submit'
  );
  const successModal = signupSection.querySelector<HTMLDivElement>(
    '.js-signup-modal'
  );
  const closeModalButton = signupSection.querySelector<HTMLAnchorElement>(
    '.js-close-button'
  );
  const formDivElement = signupSection.querySelector<HTMLDivElement>(
    '.js-signup-section'
  );

  let wasInView = false;
  let isFormEdited = false;
  const isTouchScreen =
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    (navigator as any).msMaxTouchPoints > 0;

  if (
    !sizeInputEl ||
    !sizeInputMeasureEl ||
    !emailInputEl ||
    !labelEl ||
    !fileInputEl ||
    !submitButton ||
    !successModal ||
    !closeModalButton ||
    !formDivElement
  )
    return;

  // When the file input changes, set that filename as the label
  const onFileInputChange = () => {
    labelEl.innerHTML = get(
      fileInputEl,
      'files[0].name',
      FILE_INPUT_PLACEHOLDER_TEXT
    );
    isFormEdited = true;
  };
  fileInputEl.addEventListener('change', onFileInputChange, false);

  const recalcSizeInputWidth = () => {
    const value = sizeInputEl.value || sizeInputEl.placeholder;
    sizeInputMeasureEl.innerText = value.replace(/ /g, '\xa0');
    // Add 2px to the width to make sure text in the input does not clip on different browsers.
    sizeInputEl.style.width = sizeInputMeasureEl.offsetWidth + 2 + 'px';
  };
  const onSizeInput = () => {
    recalcSizeInputWidth();
    isFormEdited = true;
  };
  sizeInputEl.oninput = onSizeInput;
  recalcSizeInputWidth();

  window.onresize = recalcSizeInputWidth;

  const onEmailInput = () => {
    isFormEdited = true;
  };
  emailInputEl.oninput = onEmailInput;

  // Listen for scroll and set caret on size input if in view.
  // Don't do this for touch screens, as it might trigger a software keyboard
  const onScroll = () => {
    const isInView = isElementInViewport(sizeInputEl);
    if (isInView && !wasInView && !isFormEdited) {
      const sizeValueLength = sizeInputEl.value.length;
      sizeInputEl.focus({ preventScroll: true });
      sizeInputEl.setSelectionRange(sizeValueLength, sizeValueLength);
    }
    wasInView = isInView;
  };
  !isTouchScreen && window.addEventListener('scroll', onScroll);

  submitButton.addEventListener('click', (event) => {
    event.preventDefault();
    if (validateContactForm()) {
      onSubmitContactForm();
      return;
    }
  });

  closeModalButton.addEventListener('click', (event) => {
    successModal.classList.add('ModalOverlay__overlay--hidden');
  });

  // Handle form validation
  const validateContactForm = () => {
    const emailValidationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validateEmail = emailValidationRegex.test(
      String(emailInputEl.value).toLowerCase()
    );
    const validateSize = !isNaN(Number(sizeInputEl.value));

    if (validateEmail && validateSize) {
      // Hide error color if shown
      formDivElement.classList.remove(signupSectionErrorClass);
      emailInputEl.classList.remove(signupInputErrorClass);
      sizeInputEl.classList.remove(signupInputErrorClass);
      return true;
    }

    // Show error color in UI
    formDivElement.classList.add(signupSectionErrorClass);
    if (!validateEmail) {
      emailInputEl.classList.add(signupInputErrorClass);
    }
    if (!validateSize) {
      sizeInputEl.classList.add(signupInputErrorClass);
    }
    // TODO: Add error message when its added to figma
    return false;
  };

  // Handle form submit
  const onSubmitContactForm = () => {
    const formData = new FormData();
    const fileField = fileInputEl;

    formData.append('email', emailInputEl.value);
    formData.append('area', sizeInputEl.value);
    if (fileField.files?.length) {
      formData.append('floorplan', fileField.files[0]);
    }

    fetch('/api/signup-form-2/', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 'error') {
          throw result.message || 'Network error';
        }
        successModal.classList.remove('ModalOverlay__overlay--hidden');
        formDivElement.classList.remove(signupSectionErrorClass);
      })
      .catch((error) => {
        console.error('Error:', error);
        formDivElement.classList.add(signupSectionErrorClass);
        // TODO: Add better error messaging to the users
      });
  };
}

export function initializeSignupForms2() {
  const signupSections: NodeListOf<HTMLDivElement> = document.querySelectorAll<HTMLDivElement>(
    '.signup-section'
  );

  signupSections.forEach((signupSection) =>
    initializeSignupForm2(signupSection)
  );
}

function isElementInViewport(el: HTMLElement) {
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
