export const initializeSignupForm = () => {

  import('../../apps/signup_form' as any).then((app) => {
    const appRoot = document.body.querySelector<HTMLDivElement>('#signup_form_root');
    const type = appRoot?.getAttribute('data-type');
    const countryCode = appRoot?.getAttribute('data-country');
    
    if(appRoot) {
      appRoot && app.render(appRoot, countryCode, type);
    }
  });
};
