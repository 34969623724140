import { initializeSidebarLayout } from './sidebar';
import { initializeSubMenuLayout } from './sub_menu'
import { initializeHeroNavLayout } from './hero_nav';

const initializePageLayouts = () => {
  initializeSidebarLayout();
  initializeSubMenuLayout();
  initializeHeroNavLayout();
};

export { initializePageLayouts };
