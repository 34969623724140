import breakpoints from '../../constants/breakpoints';

function initializeMainNavLogo() {
  const mainNav = document.querySelector('.top-menu');
  const logo = document.querySelector('.top-menu .logo');
  const hero = document.querySelector('.hero');

  if (mainNav && logo && hero) {
    const updateLogo = () => {
      const scrollPosition =
        document.documentElement.scrollTop || document.body.scrollTop;
      logo.classList.toggle(
        'visible',
        scrollPosition > hero.clientHeight - mainNav.clientHeight ||
          document.body.clientWidth < breakpoints.tablet
      );
    };

    window.addEventListener('scroll', updateLogo);
    window.addEventListener('resize', updateLogo);
    updateLogo();
  }
}


export function initializeHomeMainNavLogo() {
  initializeMainNavLogo();
}
