export function initializeFAQBlocks() {
    const faqContainers = document.querySelectorAll<HTMLDivElement>('.faq-question');
    faqContainers.forEach(function (container) {
      const link = container.querySelector<HTMLDivElement>('button');
      const questionContainer = container.querySelector<HTMLDivElement>('.question');
      if (!(link && questionContainer)) return;
      questionContainer.addEventListener('click', function (e) {
        container.classList.toggle('open');
        link.setAttribute(
          'aria-expanded',
          container.classList.contains('open') ? 'true' : 'false'
        );
      });
    });
  }
  