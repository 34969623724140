import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import queryString from 'query-string';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const handleUserJourneyPage = (page: HTMLBodyElement) => {
  const contentContainer = page.querySelector(
    '.content-container'
  ) as HTMLDivElement;
  const progressElement = page.querySelector(
    '.progress-bar .progress'
  ) as HTMLDivElement;
  const endComponent = page.querySelector('.end-component') as HTMLDivElement;
  const chapters: NodeListOf<HTMLDivElement> = page.querySelectorAll(
    '.chapter'
  );
  const chapterLabels = page.querySelectorAll('.chapter-links a');
  const mobileDropupLinks = page.querySelectorAll('.mobile-dropup a');
  const appearanceElements: NodeListOf<HTMLDivElement> = page.querySelectorAll(
    '.chapter-title, .step .description, .step .image'
  );

  let anchorLinkHandled = false;
  let isDesktop = false;

  const setProgress = (progress: number) => {
    gsap.set(progressElement, { width: `${progress * 100}%` });
  };
  const setProgressInChapter = (chapterIndex: number, progress: number) => {
    const realProgress = (chapterIndex + progress) / 3;
    setProgress(realProgress);
  };
  const setActiveLabel = (activeIndex: number) => {
    chapterLabels.forEach((label, idx) => {
      label.classList.toggle('active', idx === activeIndex);
    });
    mobileDropupLinks.forEach((label, idx) => {
      label.classList.toggle('active', idx === activeIndex);
    });
  };
  const scrollToChapter = (index: number) => {
    if (isDesktop) {
      gsap.to(window, {
        scrollTo: { x: chapters[index], offsetX: -1 },
      });
    } else {
      gsap.to(window, {
        scrollTo: { y: chapters[index], offsetY: -1 },
      });
    }
  };
  const handleAnchorLink = () => {
    if (!anchorLinkHandled) {
      const query = queryString.parse(location.search);
      if (typeof query.chapter === 'string') {
        scrollToChapter(parseInt(query.chapter));
      }
      anchorLinkHandled = true;
    }
  };

  chapterLabels.forEach((label, idx) => {
    label.addEventListener('click', (e) => {
      e.preventDefault();
      if (isDesktop) {
        scrollToChapter(idx);
      } else {
        page.querySelector('.mobile-dropup')?.classList.toggle('active');
      }
    });
  });
  mobileDropupLinks.forEach((link, idx) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      page.querySelector('.mobile-dropup')?.classList.toggle('active');
      scrollToChapter(idx);
    });
  });

  // When scrolling vertically on desktop, translate the scrolling direction to horizontal
  window.addEventListener(
    'wheel',
    (e) => {
      if (isDesktop && Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
        e.preventDefault();
        document.documentElement.scrollLeft += e.deltaY;
      }
    },
    true
  );

  const onRefresh = () => {
    if (isDesktop) {
      contentContainer.style.marginRight = `${endComponent.offsetWidth}px`;
      contentContainer.style.marginBottom = '0px';
    } else {
      contentContainer.style.marginRight = '0px';
      contentContainer.style.marginBottom = `${endComponent.offsetHeight}px`;
    }
  };
  ScrollTrigger.addEventListener('refreshInit', onRefresh);

  ScrollTrigger.matchMedia({
    '(min-width: 768px)': () => {
      isDesktop = true;
      onRefresh();

      chapters.forEach((chapter, index) => {
        let endValue: any = 'right left';
        if (index === chapters.length - 1) {
          endValue = () => (ScrollTrigger.maxScroll as any)(window, true);
        }
        ScrollTrigger.create({
          horizontal: true,
          trigger: chapter,
          start: 'left left',
          end: endValue,
          onToggle: (self) => {
            if (self.isActive) {
              setActiveLabel(index);
            }
          },
          onUpdate: (self) => setProgressInChapter(index, self.progress),
        });
      });

      appearanceElements.forEach((el) => {
        ScrollTrigger.create({
          horizontal: true,
          trigger: el,
          start: 'left right-=50px',
          onEnter: () => {
            el.classList.add('visible');
          },
        });
      });
    },
    '(max-width: 766.9px)': () => {
      isDesktop = false;
      onRefresh();

      ScrollTrigger.create({
        start: 'top top',
        end: () => ScrollTrigger.maxScroll(window as any),
        onUpdate: (self) => setProgress(self.progress),
      });

      chapters.forEach((chapter, idx) => {
        ScrollTrigger.create({
          trigger: chapter,
          start: 'top top',
          end: 'bottom top',
          onToggle: (self) => {
            if (self.isActive) {
              setActiveLabel(idx);
            }
          },
        });
      });

      appearanceElements.forEach((el) => {
        ScrollTrigger.create({
          trigger: el,
          start: 'top bottom-=50px',
          onEnter: () => {
            el.classList.add('visible');
          },
        });
      });
    },
  });

  page.querySelectorAll('.iframe-wrapper').forEach((wrapper) => {
    const iframe_url = (wrapper as HTMLDivElement).dataset.iframeUrl;
    if (iframe_url) {
      let clicked = false;
      wrapper.addEventListener('click', () => {
        if (clicked) {
          return;
        }
        clicked = true;
        const iframe = document.createElement('iframe');
        iframe.allowFullscreen = true;
        iframe.src = iframe_url;
        (iframe as any).frameBorder = 0;
        wrapper.append(iframe);
      });
    }
  });

  setTimeout(() => {
    handleAnchorLink();
  }, 500);
};

export const initializeUserJourneyPage = () => {
  const page = document.querySelector(
    'body.page-user-journey'
  ) as HTMLBodyElement | null;
  if (page) {
    handleUserJourneyPage(page);
  }
};
