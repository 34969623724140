import 'react-hot-loader';
import * as Sentry from '@sentry/browser';

import { initializeComponents } from './components';
import { initializePage } from './pages';
import { initializePageLayouts } from './page_layouts';

import 'nornorm-design-system/dist/index.css';
import '../scss/index.scss';

if (
  location.host === 'nornorm.com' ||
  location.host === 'staging.nornorm.com'
) {
  Sentry.init({
    dsn:
      'https://cf6e3de6c27a4131bb8036a358e904b5@o478645.ingest.sentry.io/5525399',
    environment: location.host === 'nornorm.com' ? 'production' : 'staging',
    ignoreErrors: [
      // Tracking from GTM
      'p161',
      // Random plugins/extensions
      'top.GLOBALS',
    ],
    denyUrls: [
      // Google Analytics and Tag manager
      /\/(gtm|ga|gtag|analytics|pixel)\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Safari extensions
      /^safari-extension:\/\//i,
    ],
  });
}

initializeComponents();
initializePage();
initializePageLayouts();
