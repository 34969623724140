import ArchilogicEmbed, { PresentationMode } from '@archilogic/embed-api';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export function initializeStyle3dShowcase(block: HTMLElement) {
  let viewer: ArchilogicEmbed | null;
  let archilogicData: {
    authorization: string;
    expiresAt: number;
    light: string;
    dark: string;
    bw: string;
  };

  function initData(block: HTMLElement) {
    let script_content = block.querySelector(
      '.archilogic-data-furniture-showcase'
    );
    if (!script_content || !script_content.textContent) return;
    archilogicData = JSON.parse(script_content.textContent);
  }

  function initBlock(block: HTMLDivElement) {
    const overlay = block.querySelector<HTMLDivElement>('.overlay');
    if (!overlay) return;
    const embed = block.querySelector<HTMLDivElement>('.embed');
    if (!embed) return;

    let timeoutId = 0;

    const enable = () => {
      dismissEnabledDelay();
      overlay.style.display = 'none';
    };

    const disable = () => {
      dismissEnabledDelay();
      overlay.style.display = 'block';
    };

    const enableDelayed = () => {
      dismissEnabledDelay();
      timeoutId = window.setTimeout(enable, 300);
    };

    const dismissEnabledDelay = () => {
      window.clearTimeout(timeoutId);
    };

    overlay.addEventListener('click', (e) => {
      enable();
    });

    overlay.addEventListener('mousemove', (e) => {
      const overlayRect = overlay.getBoundingClientRect();
      const mousePosition = e.clientY;
      const blockBottom = overlayRect.top + overlayRect.height;
      const belowTopOfButtons = mousePosition > blockBottom - 100;
      if (belowTopOfButtons) {
        enableDelayed();
      } else {
        dismissEnabledDelay();
      }
    });

    overlay.addEventListener('mouseleave', dismissEnabledDelay);

    ScrollTrigger.create({
      trigger: block,
      start: 'top bottom',
      end: 'bottom top',
      onLeave: () => disable(),
      onLeaveBack: () => disable(),
    });

    async function initEmbed(container: HTMLDivElement) {
      const options = {
          presentationMode: PresentationMode.tourLoop,
          transparentBackground: true,
          minimap: true,
          showTitle: false,
          showLogo: false,
          lowResTexturesOnly: true,
          uiButtons: {
            birdMode: true,
            personMode: true,
            fullscreen: false,
            bookmarkStrip: true,
            share: false,
            help: false,
            presentation: true,
          },
        }
      viewer = new ArchilogicEmbed(container, options);
      await viewer.loadScene(
        archilogicData.light,
        {
          temporaryAccessToken: {
            authorization: archilogicData.authorization,
            expiresAt: archilogicData.expiresAt
          }
        }).catch();
      viewer.viewerReadyPromise.then(() => loadSceneWithKey('light'));
    }

    ScrollTrigger.create({
      trigger: block,
      start: 'top bottom',
      end: 'bottom top',
      once: true,
      onEnter: () => initEmbed(embed),
    });
  }

  function loadSceneWithKey(key: 'light' | 'dark' | 'bw') {
    if (!viewer) return;
    viewer.loadScene(
      archilogicData[key],
      {
        temporaryAccessToken: {
          authorization: archilogicData.authorization,
          expiresAt: archilogicData.expiresAt
        }
      }
    ).then(() => {});
  }

  function initStyleButton(
    button: HTMLButtonElement,
    buttons: NodeListOf<HTMLButtonElement>
  ) {
    button.addEventListener('click', (e) => {
      e.stopPropagation();
      const id = button.id;
      if (id !== 'light' && id !== 'dark' && id !== 'bw') {
        return;
      }
      loadSceneWithKey(id);
      buttons.forEach((btn) => {
        btn.classList.add('button-small-secondary');
        btn.classList.remove('button-small-primary');
      });
      button.classList.remove('button-small-secondary');
      button.classList.add('button-small-primary');
    });
  }

  initData(block);

  const container = block.querySelector<HTMLDivElement>('.style-3d-showcase');
  if (!container) return;

  initBlock(container);

  const buttons = container.querySelectorAll<HTMLButtonElement>('button');
  buttons.forEach((btn) => initStyleButton(btn, buttons));
}

export function initializeStyle3dShowcases() {
  const blocks = document.querySelectorAll<HTMLElement>('.archilogic-block');
  blocks.forEach((block) => initializeStyle3dShowcase(block));
}
