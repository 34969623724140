import * as t from 'io-ts';
import get from 'lodash/get';
import find from 'lodash/find';

const StylesAndSetsItem = t.type({
  style: t.string,
  space_type: t.string,
  archilogic_scene_id: t.string,
  main_image: t.type({
    url: t.string,
    alt: t.string,
  }),
  supporting_image_1: t.type({
    url: t.string,
    alt: t.string,
  }),
  supporting_image_2: t.type({
    url: t.string,
    alt: t.string,
  }),
});
type StylesAndSetsItem = t.TypeOf<typeof StylesAndSetsItem>;

const StylesAndSetsFetchResponse = t.type({
  records: t.array(StylesAndSetsItem),
});

function initializeStyleExplorer(styleExplorer: HTMLDivElement) {
  const mainImage = styleExplorer.querySelector(
    '.style-explorer__image-gallery__main .js-img'
  );
  const sideImages = styleExplorer.querySelectorAll(
    '.style-explorer__image-gallery__side .js-img'
  );

  const tabs = styleExplorer.querySelectorAll('.tabbar .tab');
  const styleSelect = styleExplorer.querySelector(
    '.dropdown select'
  ) as HTMLSelectElement;
  const styleSelectOptions = styleExplorer.querySelectorAll(
    '.dropdown select option'
  );
  const styleButtonLabel = styleExplorer.querySelector(
    '.dropdown button span'
  ) as HTMLSpanElement;
  const tags = styleExplorer.querySelectorAll('.style-explorer__tagbar a');

  let currentSet = 'Lounge';
  const setCurrentSet = (value: string) => {
    currentSet = value;
    updateImages();
  };

  let currentStyle: string = 'Light';
  const setCurrentStyle = (value: string) => {
    currentStyle = value || '';
    // Set selected option as label of select button
    styleButtonLabel.innerHTML = currentStyle;
    // Set selected option of select element
    styleSelectOptions.forEach((option, i) => {
      if (option.getAttribute('value') === currentStyle)
        styleSelect.selectedIndex = i;
    });
    // Set selected tag in tagbar
    tags.forEach((tag) =>
      tag.getAttribute('data-id') === currentStyle
        ? tag.classList.add('selected')
        : tag.classList.remove('selected')
    );
    updateImages();
  };

  if (
    !mainImage ||
    !sideImages ||
    !tabs ||
    !styleSelect ||
    !styleButtonLabel ||
    !styleSelectOptions
  )
    return;

  tabs.forEach((tab) =>
    tab.addEventListener('click', () =>
      setCurrentSet(tab.getAttribute('data-id') || 'Lounge')
    )
  );

  tags.forEach((tag) =>
    tag.addEventListener('click', () =>
      setCurrentStyle(tag.getAttribute('data-id') || 'Light')
    )
  );

  styleSelect.addEventListener('change', (event) => {
    const newStyle: string = event && get(event, 'target.value');
    setCurrentStyle(newStyle);
  });

  // Get the records, then the result the our current values
  let records: StylesAndSetsItem[] = [];
  fetchData().then((data) => {
    if (StylesAndSetsFetchResponse.is(data)) {
      records = data.records;
      updateImages();
    }
  });

  const updateImages = () => {
    const record = find<StylesAndSetsItem>(records, {
      space_type: currentSet,
      style: currentStyle,
    });
    if (!record) {
      return;
    }
    const webImage = get(record, 'main_image');
    const supportingWebImages = [
      get(record, 'supporting_image_1'),
      get(record, 'supporting_image_2'),
    ];

    mainImage.setAttribute('src', get(webImage, 'url', ''));
    mainImage.setAttribute('alt', get(webImage, 'alt', ''));
    sideImages[0].setAttribute('src', get(supportingWebImages, '[0].url', ''));
    sideImages[0].setAttribute('alt', get(supportingWebImages, '[0].alt', ''));
    sideImages[1].setAttribute('src', get(supportingWebImages, '[1].url', ''));
    sideImages[1].setAttribute('alt', get(supportingWebImages, '[1].alt', ''));
  };

  // Select Random set and style on load:
  const randomSet = tabs[Math.floor(Math.random() * tabs.length)];
  const randomStyle =
    styleSelectOptions[Math.floor(Math.random() * styleSelectOptions.length)];
  setCurrentStyle(randomStyle.getAttribute('value') || 'Light');
  setCurrentSet(randomSet.getAttribute('data-id') || 'Lounge');
  randomSet.classList.add('selected');
}

export function initializeStyleExplorers() {
  const styleExplorers: NodeListOf<HTMLDivElement> =
    document.querySelectorAll<HTMLDivElement>('.style-explorer');
  styleExplorers.forEach((styleExplorer) =>
    initializeStyleExplorer(styleExplorer)
  );
}

function fetchData(): Promise<unknown> {
  return fetch('/api/styles-and-sets-data/').then((response) =>
    response.json()
  );
}
