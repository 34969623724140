// Keep track, so we only do style class operations if the flag changes
let prevScrolledPastHero:boolean = false;

const updateNavStyle = (nav:HTMLDivElement, hero:HTMLDivElement):void => {
  const heroTop = hero.getBoundingClientRect().top;
  const heroHeight = hero.clientHeight;
  const scrolledPastHero = (-heroTop > heroHeight);

  if(scrolledPastHero !== prevScrolledPastHero) {
    prevScrolledPastHero = scrolledPastHero;
    scrolledPastHero
      ? nav.classList.add('past-hero')
      : nav.classList.remove('past-hero');
  }
}

function adaptNavToHero() {
  const body = document.querySelector<HTMLDivElement>('body');
  const nav = document.querySelector<HTMLDivElement>('.js-hero-page .top-menu');
  const hero = document.querySelector<HTMLDivElement>('.js-hero-page .js-hero-section');

  if(!body || !nav || !hero) return;

  window.addEventListener('scroll', () => {
    updateNavStyle(nav, hero);
  })
  updateNavStyle(nav, hero);
}

export function initializeHeroNavLayout() {
  adaptNavToHero();
}
