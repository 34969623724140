import { ICurrencyOption, DEFAULT_LOCATION_ID, getLocationById, ILocation } from '../data/pricing';

const handlePricingPage = (page: HTMLBodyElement) => {
  const subscriptionBoxMonthlyPrice:HTMLDivElement|null = page.querySelector('.subscription-monthly-price .price');
  const subscriptionBoxEntryPrice:HTMLDivElement|null = page.querySelector('.entry-fee .price');
  const clientReferencePrices:NodeListOf<HTMLDivElement> = page.querySelectorAll('.client-card .price-label');

  const onHTMLMutate = (mutationsList: Array<MutationRecord>) => {
    mutationsList.forEach((mutation: MutationRecord) => {
      if(mutation.attributeName === "data-preferred-location") {
        updateCurrenciesOnPage();
      }
    })
  }

  const observer = new MutationObserver(onHTMLMutate);
  observer.observe(document.documentElement, {attributes: true});


  const updateCurrenciesOnPage = () => {
    // Get preferred location id, if possible, otherwise use default
    const currentLocationId:string = document.documentElement.getAttribute('data-preferred-location') || DEFAULT_LOCATION_ID;
    const currentLocation:ILocation|undefined = getLocationById(currentLocationId);

    if(!currentLocation) return;

    const currency:ICurrencyOption = currentLocation.currency;

    if(currency) {
      if( subscriptionBoxMonthlyPrice )
        subscriptionBoxMonthlyPrice.innerHTML = currency.signifier + currency.pricePerArea;
      if( subscriptionBoxEntryPrice )
        subscriptionBoxEntryPrice.innerHTML = currency.signifier + currency.startupPrice;
      clientReferencePrices.forEach((clientPrice:HTMLDivElement) => {
          const price = Number(clientPrice.getAttribute('data-size')) * currency.pricePerArea;
          clientPrice.innerHTML = currency.signifier + price + ' / month'; 
      });
    }
  }

  updateCurrenciesOnPage();
}

export const initializePricingPage = () => {
  const page = document.querySelector(
    'body.page-pricing'
  ) as HTMLBodyElement | null;
  if (page) {
    handlePricingPage(page);
  }
};
