import { openGetStartedModal } from './get_started_modal';


export const initializeGetStartedExploreSection = () => {
  const parentContainer = document.querySelector<HTMLDivElement>('.get-started-explore-module');
  const buttons = document.querySelectorAll<HTMLDivElement>('.get-started-explore-module .office-intro-buttons a');

  if(parentContainer && buttons) {
    buttons.forEach((button) => {
      button.addEventListener('click', event => {
        const target:HTMLElement = event.currentTarget as HTMLElement;
        if(target) {
          const officeStyle:string|undefined = target.getAttribute('data-style') || undefined;
          openGetStartedModal(officeStyle);
        }        
      });
    });
  }

};
