import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const initializeRangeExplorer = () => {
  const containers = document.querySelectorAll<HTMLDivElement>(
    '.range-explorer-root'
  );
  containers.forEach((container) => {
    const languageCode = container.getAttribute('data-language');

    ScrollTrigger.create({
      trigger: container,
      start: 'top bottom',
      end: 500,
      once: true,
      onEnter: () => {
        import('../../apps/range_explorer').then((rangeExplorer) =>
          rangeExplorer.render(container)
        );
      },
    });
  });
};
