export const initializeChatbotSection = () => {
  const chatOverlay = document.querySelector<HTMLDivElement>('.js-chatbot-overlay');
  const buttons = document.querySelectorAll<HTMLDivElement>(
    `.js-chatbot-activator,
    [href="#open-price-chatbot"]`
  );

  if(chatOverlay && buttons) {
    buttons.forEach((button) => {
      button.addEventListener('click', event => {
        const target:HTMLElement = event.currentTarget as HTMLElement;
        if(target) {
          const closeButton:HTMLButtonElement|null = chatOverlay.querySelector<HTMLButtonElement>('.js-close-overlay');
          closeButton?.addEventListener('click', closeGetStartedModal);
          document.body.classList.add('noscroll');
          chatOverlay.classList.add('visible')
        }        
      });
    });

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      chatOverlay.style.setProperty('--vh', `${vh}px`);
    });
  }

  const closeGetStartedModal = () => {
    document.body.classList.remove('noscroll');
    chatOverlay?.classList.remove('visible');
  } 
};
