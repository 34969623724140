
import { initializeHomeZoomGallery } from './zoom_gallery';
import { initializeHomeMainNavLogo } from './mainNavLogo';

const initializeHome = () => {
  initializeHomeMainNavLogo();
  initializeHomeZoomGallery();
};

export { initializeHome };
