function prettyRound(value: number) {
  let base: number;
  if (value < 20) {
    base = 1;
  } else if (value < 50) {
    base = 2;
  } else if (value < 100) {
    base = 5;
  } else if (value < 500) {
    base = 10;
  } else if (value < 1000) {
    base = 50;
  } else if (value < 5000) {
    base = 100;
  } else if (value < 10000) {
    base = 500;
  } else if (value < 50000) {
    base = 1000;
  } else if (value < 100000) {
    base = 5000;
  } else {
    base = 10000;
  }
  return Math.round(value / base) * base;
}

export function logarithmicRange(from: number, to: number, count: number) {
  const minv: number = Math.log(from);
  const maxv: number = Math.log(to);
  const scale: number = (maxv - minv) / (count - 1);

  const range: number[] = [];
  for (let i = 0; i < count; i += 1) {
    const value: number = Math.exp(minv + scale * i);
    const rounded: number = prettyRound(value);
    if (range.indexOf(rounded) === -1) {
      range.push(rounded);
    }
  }
  return range;
}
