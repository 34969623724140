import Swiper, { Navigation } from 'swiper';
import breakpoints from '../constants/breakpoints';
import { debounce } from '../utils/debounce';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {
  initUSPAnimation1,
  initUSPAnimation2,
  initUSPAnimation3,
} from './usp_animations';

Swiper.use([Navigation]);

function initializeFeatureSlider(featureSliderEl: HTMLDivElement) {
  const swiperEl =
    featureSliderEl.querySelector<HTMLDivElement>('.swiper-container') ||
    '.swiper-container';
  const swiperPrevEl =
    featureSliderEl.querySelector<HTMLDivElement>('.control-button-left') ||
    '.swiper-button-prev';
  const swiperNextEl =
    featureSliderEl.querySelector<HTMLDivElement>('.control-button-right') ||
    '.swiper-button-next';
  const slides: NodeListOf<HTMLDivElement> = featureSliderEl.querySelectorAll(
    '.swiper-slide'
  );
  const cursor = featureSliderEl.querySelector<HTMLDivElement>('.cursor');

  let swiper: Swiper | null;

  function initSwiper() {
    swiper = new Swiper(swiperEl, {
      speed: 500,
      navigation: {
        prevEl: swiperPrevEl,
        nextEl: swiperNextEl,
      },
      on: {
        slideChange: onSlideChange,
      },
      breakpoints: {
        [breakpoints.tablet]: {
          loop: true,
          loopedSlides: slides.length,
          allowTouchMove: false,
        },
      },
    });

    updateCurrentPage();
    updateNavigationState();

    swiper.on('slideChange', () => {
      updateCurrentPage();
      updateNavigationState();
      updateCursor();
    });
  }

  function updateCurrentPage() {
    if (swiper) {
      const currentPageEl = featureSliderEl.querySelector<HTMLDivElement>(
        '.current-page'
      );

      if (currentPageEl) {
        currentPageEl.innerHTML = (swiper.realIndex + 1).toString();
      }
    }
  }

  function updateNavigationState() {
    if (swiper) {
      if (swiper.realIndex === 0) {
        swiper.navigation.prevEl.setAttribute('disabled', 'disabled');
      } else {
        swiper.navigation.prevEl.removeAttribute('disabled');
      }

      if (swiper.realIndex + 1 === slides.length) {
        swiper.navigation.nextEl.setAttribute('disabled', 'disabled');
      } else {
        swiper.navigation.nextEl.removeAttribute('disabled');
      }
    }
  }

  function updateCursor() {
    if (swiper && cursor) {
      const cursorText = cursor.querySelector<HTMLDivElement>('.cursor-text');

      if (cursorText) {
        if (swiper.realIndex === slides.length - 1) {
          cursorText.innerHTML = 'Back';
        } else {
          cursorText.innerHTML = 'Next';
        }
      }
    }
  }

  function removeDuplicates() {
    const duplicates: NodeListOf<HTMLDivElement> = document.querySelectorAll(
      '.swiper-slide-duplicate'
    );

    duplicates.forEach((duplicate) => {
      duplicate.remove();
    });
  }

  function handleWindowResize() {
    /**
     * Here we need to destroy the Swiper and re-initialise it.
     * This is because we need different Swiper settings to be
     * applied for looping.
     **/
    if (swiper) {
      swiper.destroy();
      removeDuplicates();
    }

    initSwiper();
  }

  function addEventListeners() {
    featureSliderEl.addEventListener('click', () => {
      if (window.innerWidth >= breakpoints.tablet && swiper) {
        swiper.slideNext();
      }
    });

    const debouncedResize = debounce(handleWindowResize, 250);
    window.addEventListener('resize', debouncedResize);
  }

  /* Handle animations */

  const illustrations: NodeListOf<SVGElement> = featureSliderEl.querySelectorAll(
    '.item svg'
  );
  const animations = [
    initUSPAnimation1(illustrations[0]),
    initUSPAnimation2(illustrations[1]),
    initUSPAnimation3(illustrations[2]),
  ];

  // First animation triggers when it comes into view
  ScrollTrigger.create({
    animation: animations[0],
    trigger: slides[0],
    start: 'top center',
  });

  // All slides trigger when they slide in
  function onSlideChange(event: any) {
    animations.forEach((animation, idx) => {
      if (idx === event.snapIndex) {
        animation.play(0);
      } else {
        animation.pause();
      }
    });
  }

  initSwiper();
  addEventListeners();
}

export function initializeFeatureSliders() {
  const featureSliders: NodeListOf<HTMLDivElement> = document.querySelectorAll(
    '.feature-slider'
  );
  featureSliders.forEach(initializeFeatureSlider);
}
