import { initializeFAQBlocks } from './faq';
import { initializeHome } from './home/';
import { initializeUserJourneyPage } from './user_journey';
import { initializePricingPage } from './pricing';

const initializePage = () => {
  initializeFAQBlocks();
  initializeHome();
  initializeUserJourneyPage();
  initializePricingPage();
};

export { initializePage };
