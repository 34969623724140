import breakpoints from '../constants/breakpoints';
import { debounce } from '../utils/debounce';

function initializeCursor(cursorEl:HTMLDivElement) {
  const parentEl = <HTMLElement>cursorEl.parentNode;

  let mouseX = 0;
  let mouseY = 0;
  let currentX = 0;
  let currentY = 0;
  let animatingCursor = false;

  function handleMouseMove(event: MouseEvent) {
    if (cursorEl && parentEl) {
      const bounds = parentEl.getBoundingClientRect();
      mouseX = (event.clientX - bounds.left) - (cursorEl.offsetWidth / 2);
      mouseY = (event.clientY - bounds.top) - (cursorEl.offsetHeight / 2);
    }
  }

  function handleWheel(event: WheelEvent) {
    if (cursorEl && parentEl) {
      const containerHeight = parentEl.offsetHeight;

      mouseY = mouseY + event.deltaY;

      if (mouseY < 0) {
        mouseY = 0 - (cursorEl.offsetHeight / 2);
      }

      if (mouseY > containerHeight) {
        mouseY = containerHeight - (cursorEl.offsetHeight / 2);
      }
    }
  }

  function animateCursor() {
    if (window.innerWidth >= breakpoints.tablet) {
      const dX = (mouseX - currentX);
      const dY = (mouseY - currentY);

      currentX = currentX + (dX * 0.2);
      currentY = currentY + (dY * 0.2);

      if (cursorEl) {
        cursorEl.style.transform = `translate3d(${currentX}px, ${currentY}px, 0)`;
      }

      if (Math.abs(dX) < 1 && Math.abs(dY) < 1) {
        animatingCursor = false;
      } else {
        requestAnimationFrame(animateCursor);
      }
    }
  }

  function handleWindowResize() {
    animatingCursor = false;
  }

  function initCursorAnimation() {
    if (!animatingCursor) {
      animatingCursor = true;
      requestAnimationFrame(animateCursor);
    }
  }

  function addEventListeners() {
    if (parentEl) {
      parentEl.addEventListener('mouseover', (event: MouseEvent) => {
        if (window.innerWidth >= breakpoints.tablet) {
          handleMouseMove(event);
        }
      });

      parentEl.addEventListener('mousemove', (event: MouseEvent) => {
        if (window.innerWidth >= breakpoints.tablet) {
          handleMouseMove(event);
          initCursorAnimation();
        }
      });

      parentEl.addEventListener('wheel', (event: WheelEvent) => {
        if (window.innerWidth >= breakpoints.tablet) {
          handleWheel(event);
          initCursorAnimation();
        }
      });
    }

    const debouncedResize =  debounce(handleWindowResize, 250);
    window.addEventListener('resize', debouncedResize);
  }

  addEventListeners();
}

export function initializeCursors() {
  const cursors: NodeListOf<HTMLDivElement> = document.querySelectorAll('.cursor');
  cursors.forEach(initializeCursor);
}
