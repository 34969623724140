export function offset(element: HTMLElement) {
  let el: HTMLElement | null = element;
  let offsetLeft = 0;
  let offsetTop = 0;

  do {
    offsetLeft += el.offsetLeft;
    offsetTop += el.offsetTop;
    el = el.offsetParent as HTMLElement | null;
  } while (el);

  return {
    top: offsetTop,
    left: offsetLeft,
  };
}
