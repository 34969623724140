// This script handles the anchor link clicking the sub nav (in top menu) and sidebar.
// It also helps the top menu respond appropriately to scroll events by indicating whether
// the scroll happened due to an anchor link click or not.

import filter from 'lodash/filter';
import forEach from 'lodash/forEach';

import { scrollTo } from '../utils/scroll';

const onAnchorLinkClick = (e: Event) => {
  e.preventDefault();

  const anchorTargetSelector: string = (e.target as HTMLAnchorElement).hash;
  const anchorTarget: HTMLElement | null = document.querySelector(
    anchorTargetSelector
  );

  if (!anchorTarget) return;
  const offsetY = isSidebarLink(e.target as HTMLElement)
    ? getSidebarPageOffsetY()
    : 100;
  const isScrollingToAnchor = true;

  scrollTo(anchorTarget, offsetY, isScrollingToAnchor);
};

const isSidebarLink = (element: HTMLElement): boolean =>
  document.querySelector('.sidebar ul')?.contains(element) as boolean;
const getSidebarPageOffsetY = () =>
  (document.querySelector('.sidebar ul') as HTMLElement).getBoundingClientRect()
    .top || 0;

export const initializeSecondaryMenuScrolling = () => {
  // Gather all links from sub nav and sidebar
  const menuLinks: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(
    '.sub-nav a, .sidebar a'
  );
  // Limit to internal links only
  const anchorLinks = filter(
    menuLinks,
    (link: HTMLAnchorElement) => link.hash.charAt(0) === '#'
  );

  forEach(anchorLinks, (link) =>
    link.addEventListener('click', onAnchorLinkClick)
  );
};
