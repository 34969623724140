import gsap from 'gsap';

const commonAnimationConfig = {
  repeat: -1,
  yoyo: true,
  repeatDelay: 0.5,
  ease: 'power2.inOut',
  paused: true,
};

export const initUSPAnimation1 = (svg: SVGElement): TweenLite => {
  const rings: NodeListOf<SVGCircleElement> | undefined = svg?.querySelectorAll(
    'circle'
  );
  return gsap.from(rings, {
    ...commonAnimationConfig,
    cx: 75,
    duration: 1.5,
    stagger: -0.1,
  });
};

export const initUSPAnimation2 = (svg: SVGElement): TweenLite => {
  const rightRing: SVGElement | null = svg?.querySelector('circle:last-child');
  return gsap.from(rightRing, {
    ...commonAnimationConfig,
    cx: 75,
    duration: 2,
  });
};

export const initUSPAnimation3 = (svg: SVGElement): TweenLite => {
  const ellipses:
    | NodeListOf<SVGEllipseElement>
    | undefined = svg?.querySelectorAll('ellipse');
  return gsap.from(ellipses, {
    ...commonAnimationConfig,
    rx: 74,
    duration: 1.5,
    stagger: -0.1,
  });
};
