import { initializeFeatureSliders } from './feature_slider';
import { initializeHorizontalDraggers } from './horizontal_dragger';
import { initializeImageGalleries } from './image_gallery';
import { initializePriceCalculators } from './price_calculator/';
import { initializeRangeExplorer } from './range_explorer';
import { initializeStyle3dShowcases } from './style_3d_showcase';
import { initializeUspCircle } from './usp_circle';
import { initializeGetStartedExploreSection } from './get_started_explore_section';
import { initializeExploreButtons } from './explore_button';
import { initializeCursors } from './cursor';
import { initializeMainNav } from './top_menu';
import { initializeCategoryFilters } from './category_filter';
import { initializeSecondaryMenuScrolling } from './secondary_menu_scroll';
import { initializeSignupForm } from './signup_form';
import { initializeSignupForms2 } from './signup_form_2';
import { initializeUSPSections } from './usp_section';
import { initializeTabbars } from './tabbar';
import { initializeStyleExplorers } from './style_explorer';
import { initializeChatbotSection } from './chatbot';
import { initializeImageSlideshows } from './image_slideshow';

const initializeComponents = () => {
  initializeFeatureSliders();
  initializeHorizontalDraggers();
  initializeImageGalleries();
  initializePriceCalculators();
  initializeRangeExplorer();
  initializeStyle3dShowcases();
  initializeUspCircle();
  initializeGetStartedExploreSection();
  initializeExploreButtons();
  initializeCursors();
  initializeMainNav();
  initializeCategoryFilters();
  initializeSecondaryMenuScrolling();
  initializeSignupForm();
  initializeSignupForms2();
  initializeUSPSections();
  initializeTabbars();
  initializeStyleExplorers();
  initializeChatbotSection();
  initializeImageSlideshows();
};

export { initializeComponents };
