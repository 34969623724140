import { openGetStartedModal } from './get_started_modal';

const initializeExploreButton = (button:HTMLButtonElement) => (
  button && button.addEventListener('click', () => {
    openGetStartedModal(undefined, "officeStyle");
  })
);

export const initializeExploreButtons = () => {
  const buttons = document.querySelectorAll<HTMLButtonElement>(
    `.explore-button,
    [href="#open-explore-chat"]`
  );
  buttons.forEach(button => initializeExploreButton(button));
}