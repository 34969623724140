import isElementInViewport from '../utils/isInView';
import throttle from 'lodash/throttle';

const MINIMUM_INTERVAL = 4000; // Image will change in this amount of milliseconds (or longer, if it takes longer to load)
const TRANSITION_DELAY = 50; // Required to trigger the CSS transition

// TODO: Get rid of "any". Unsure how to handle the specific interface
// required by the image's callbacks.
function loadNextImage(url: string, onLoadSuccess: any, onLoadError: any) {
  let image = new Image();
  image.onload = onLoadSuccess;
  image.onerror = onLoadError;
  image.src = url;
  image.srcset = url;
}

function initializeImageSlideshow(slideshow: HTMLDivElement) {
  const currentImage: HTMLImageElement | null =
    slideshow.querySelector('#current_image');
  // The previous image element is used to fade out the last image,
  // while the next image is loaded as the current image
  const previousImage: HTMLImageElement | null =
    slideshow.querySelector('#previous_image');

  let currentImageIndex: number = 0;
  let imageUrls: string[] = [];

  let nextImageUrl: string = '';
  let isNextImageLoaded = false;
  let isNextImageDue = false;

  // Get all provided image urls
  for (let i = 0; i < slideshow.attributes.length; i++) {
    const attribute = slideshow.attributes.item(i);
    if (attribute && attribute.name.startsWith('data-image-url-'))
      imageUrls.push(attribute.value);
  }

  // If we don't have more than one image, there is no point is doing a slideshow
  if (imageUrls.length < 2) return;

  const handleScrollThrottled = throttle(handleScroll, 200);
  window.onscroll = handleScrollThrottled;

  function getNextIndex() {
    return (currentImageIndex + 1) % imageUrls.length;
  }

  function preloadNextImage() {
    isNextImageLoaded = false;
    isNextImageDue = false;
    nextImageUrl = imageUrls[getNextIndex()];
    loadNextImage(nextImageUrl, handleImageLoadSuccess, handleImageLoadError);

    setTimeout(handleNextImageDue, MINIMUM_INTERVAL);
  }

  function goToNextImage() {
    if (!currentImage || !previousImage) return;

    previousImage.src = currentImage.src;
    previousImage.srcset = currentImage.srcset;
    previousImage.classList.add('shown');
    currentImage.src = nextImageUrl;
    currentImage.srcset = nextImageUrl;
    setTimeout(() => previousImage.classList.remove('shown'), TRANSITION_DELAY);

    currentImageIndex = getNextIndex();
    preloadNextImage();
  }

  function handleScroll() {
    checkIfGoToNextImage();
  }

  function handleImageLoadSuccess() {
    isNextImageLoaded = true;
    checkIfGoToNextImage();
  }

  function handleNextImageDue() {
    isNextImageDue = true;
    checkIfGoToNextImage();
  }

  function checkIfGoToNextImage() {
    if (
      currentImage &&
      isNextImageLoaded &&
      isNextImageDue &&
      isElementInViewport(currentImage)
    ) {
      goToNextImage();
    }
  }

  function handleImageLoadError() {
    // If the image didn't load, try to preload the next image
    preloadNextImage();
  }

  // Initial preload
  preloadNextImage();
}

export function initializeImageSlideshows() {
  const slideshows: NodeListOf<HTMLDivElement> =
    document.querySelectorAll('.image-slideshow');
  slideshows.forEach(initializeImageSlideshow);
}
