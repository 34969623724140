import { registerScrollListener, ScrollDirection } from '../utils/scroll';

function addMobileMenuBehaviour() {
  const body: HTMLBodyElement | null = document.querySelector('body');
  const topMenu: HTMLDivElement | null = document.querySelector('.js-top-menu');
  if (!topMenu) return;

  const mobileMenuOverlay: HTMLDivElement | null = topMenu.querySelector(
    '.js-mobile-menu-overlay'
  );
  const openButton: HTMLButtonElement | null = topMenu.querySelector(
    '.js-open-mobile-menu-button'
  );
  const closeButton: HTMLButtonElement | null = topMenu.querySelector(
    '.js-close-mobile-menu-button'
  );

  if (body && mobileMenuOverlay && openButton && closeButton) {
    openButton.addEventListener('click', () => {
      mobileMenuOverlay.classList.add('open');
      body.classList.add('noscroll');
    });
    closeButton.addEventListener('click', () => {
      mobileMenuOverlay.classList.remove('open');
      body.classList.remove('noscroll');
    });
  }
}

function addDesktopOverflowBehaviour() {
  const defaultLinksGroup = document.querySelector('.js-primary-links-desktop');
  const defaultLinksList = document.querySelector(
    '.js-primary-links-desktop > ul'
  ) as HTMLElement;
  const defaultLinks = document.querySelectorAll(
    '.js-default-links > li:not(.--more)'
  ) as NodeListOf<HTMLElement>;

  if (
    !defaultLinksGroup ||
    !defaultLinksList ||
    !defaultLinks ||
    defaultLinks.length === 0
  )
    return;

  defaultLinksList.insertAdjacentHTML(
    'beforeend',
    `
  <li class="js-more-item">
    <a class="text--shade-1 more-btn js-more-btn" type="button" aria-haspopup="true" aria-expanded="false">
      More
    </a>
    <ul class="overflow-links-list js-overflow-links-list">
      ${defaultLinksList.innerHTML}
    </ul>
  </li>
`
  );
  const overflowLinksList = defaultLinksGroup.querySelector(
    '.js-overflow-links-list'
  );
  if (!overflowLinksList) return;

  const overflowLinks = overflowLinksList.querySelectorAll('li');
  const allLinks = defaultLinksGroup.querySelectorAll('li');

  const moreLi = defaultLinksList.querySelector('.js-more-item');
  if (!moreLi) return;

  const moreBtn = moreLi.querySelector('.js-more-btn') as HTMLElement;
  if (!moreBtn) return;

  moreBtn.addEventListener('click', (e) => {
    e.preventDefault();
    defaultLinksGroup.classList.toggle('--show-overflow-links-list');
    moreBtn.setAttribute(
      'aria-expanded',
      String(defaultLinksGroup.classList.contains('--show-overflow-links-list'))
    );
  });

  // adapt tabs
  const doAdapt = () => {
    // reveal all items for the calculation
    allLinks.forEach((item) => {
      item.classList.remove('--hidden');
    });

    // hide items that won't fit in the Primary
    let stopWidth = moreBtn.offsetWidth;
    let hiddenItems = new Array<number>();
    const primaryWidth = defaultLinksList.offsetWidth;

    defaultLinks.forEach((item, i) => {
      if (primaryWidth >= stopWidth + item.offsetWidth) {
        stopWidth += item.offsetWidth;
      } else {
        item.classList.add('--hidden');
        hiddenItems.push(i);
      }
    });

    // toggle the visibility of More button and items in Secondary
    if (!hiddenItems.length) {
      moreLi.classList.add('--hidden');
      defaultLinksGroup.classList.remove('--show-overflow-links-list');
      moreBtn.setAttribute('aria-expanded', 'false');
    } else {
      overflowLinks.forEach((item, i) => {
        if (!hiddenItems.includes(i)) {
          item.classList.add('--hidden');
        }
      });
    }
  };

  doAdapt(); // adapt immediately on load
  window.addEventListener('resize', doAdapt); // adapt on window resize

  // hide Secondary on the outside click
  document.addEventListener('click', (e) => {
    let el = e.target as (Node & ParentNode) | null;
    while (el) {
      if (el === overflowLinksList || el === moreBtn) {
        return;
      }
      el = el.parentNode;
    }
    defaultLinksGroup.classList.remove('--show-overflow-links-list');
    moreBtn.setAttribute('aria-expanded', 'false');
  });
}

function addDesktopHideOnScrollBehaviour() {
  const topMenu = document.querySelector('.js-top-menu');
  if (!topMenu) return;

  let lastScrollPosition = 0;
  let lastDirection = 1;

  function update() {
    if (!topMenu) return;

    let navMenuHidden = false;
    let navNotAtTop = false;

    const scrollPosition =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (scrollPosition <= 40) {
      navMenuHidden = false;
    } else {
      navNotAtTop = true;
      if (lastDirection < 0) {
        navMenuHidden = scrollPosition > lastScrollPosition;
      } else {
        navMenuHidden = scrollPosition >= lastScrollPosition;
      }
      lastDirection = navMenuHidden ? 1 : -1;
    }
    lastScrollPosition = scrollPosition;

    if (navMenuHidden) {
      topMenu.classList.add('hidden');
    } else {
      topMenu.classList.remove('hidden');
    }

    if (navNotAtTop) {
      topMenu.classList.add('not-at-top');
    } else {
      topMenu.classList.remove('not-at-top');
    }
  }

  registerScrollListener(
    (isScrollingToAnchor: boolean, scrollDirection: ScrollDirection) => {
      if (!isScrollingToAnchor || scrollDirection === ScrollDirection.DOWN)
        update();
    }
  );
}

function addOptionalSubNavItems() {
  const topMenu = document.querySelector('.js-top-menu');
  if (!topMenu) return;

  /* Add sub-nav items (if elements on page with `data-in-sub-nav=true` attribute )*/
  const addAnchorLink = (title: string, id: string, parent: HTMLElement) => {
    const link: HTMLAnchorElement = document.createElement('a');
    link.classList.add(
      'text--style-body',
      'text--shade-2',
      'ml-xxs',
      'mr-xxs',
      'mb-xxxxs'
    );
    link.innerText = title;
    link.href = `#${id}`;
    parent.append(link);
  };

  const anchors: NodeListOf<HTMLElement> = document.querySelectorAll(
    '[data-in-sub-nav="true"]'
  );

  if (anchors.length > 0) {
    topMenu.classList.add('with-sub-nav');

    const subNavWrapper = document.createElement('div');
    subNavWrapper.classList.add('sub-nav-wrapper', 'section--padding-hor');
    topMenu.appendChild(subNavWrapper);

    const subNav = document.createElement('div');
    subNav.classList.add('sub-nav', 'section--padding-hor');
    subNavWrapper.appendChild(subNav);

    anchors.forEach((anchor) => {
      const anchorLabel: string =
        anchor.getAttribute('data-sub-nav-label') || '[Label missing]';
      addAnchorLink(anchorLabel, anchor.id, subNav);
    });
  }
}

export function initializeMainNav() {
  addMobileMenuBehaviour();
  addDesktopOverflowBehaviour();
  addDesktopHideOnScrollBehaviour();
  addOptionalSubNavItems();
}
