import find from 'lodash/find';

/* INTERFACES */

export interface ICurrencyOption {
  id: string,
  label: string,
  signifier: string,
  pricePerArea: number,
  startupPrice: number,
}

export interface ILocation {
  id: string,
  label: string,
  currency: ICurrencyOption
}


/* CURRENCY OPTIONS */

export const CURRENCY_EURO:ICurrencyOption = {
  id: 'euro',
  label: 'EUR',
  signifier: '€',
  pricePerArea: 3,
  startupPrice: 18,
};

export const CURRENCY_SGD:ICurrencyOption = {
  id: 'singaporeDollar',
  label: 'SGD',
  signifier: 'S$',
  pricePerArea: 4.5,
  startupPrice: 27,
};

export const CURRENCY_OPTIONS:Array<ICurrencyOption> = [
  CURRENCY_EURO,
  CURRENCY_SGD
];


/* LOCALES */

export const LOCATION_NL:ILocation = {
  id: 'nl',
  label: 'The Netherlands',
  currency: CURRENCY_EURO,
};

export const LOCATION_DK:ILocation = {
  id: 'dk',
  label: 'Denmark',
  currency: CURRENCY_EURO,
};

export const LOCATION_SE:ILocation = {
  id: 'se',
  label: 'Sweden',
  currency: CURRENCY_EURO,
};

export const LOCATION_DE:ILocation = {
  id: 'de',
  label: 'Germany',
  currency: CURRENCY_EURO,
};

export const LOCATION_SG:ILocation = {
  id: 'sg',
  label: 'Singapore',
  currency: CURRENCY_SGD,
};

export const LOCATIONS:Array<ILocation> = [
  LOCATION_NL,
  LOCATION_DK,
  LOCATION_SE,
  LOCATION_DE,
  LOCATION_SG,
];

export const DEFAULT_LOCATION_ID = LOCATIONS[0].id;

export const getCurrencyOptionById = (id:string):ICurrencyOption|undefined => (
  find(CURRENCY_OPTIONS, {id})
);

export const getLocationById = (id:string):ILocation|undefined => (
  find(LOCATIONS, {id})
);