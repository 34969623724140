import { offset } from '../utils/offset';
import { registerScrollListener } from '../utils/scroll';

const initializeSubMenuLayout = () => {
  
  const subNavSection = document.querySelectorAll<HTMLElement>(
    '.top-menu.with-sub-nav'
  );
  subNavSection.forEach((section) => {
    const subNav = section.querySelector(".sub-nav");
    if (!subNav) return;

    // Provide scroll animation for anchor links
    const anchorLinks = Array.from(
      subNav.querySelectorAll<HTMLAnchorElement>('a[href^="#"]')
    );
    const anchorTargets = anchorLinks.map((link) => {
      const selector = link.getAttribute('href')!;
      return document.querySelector<HTMLElement>(selector);
    });

    const setActiveAnchorLink = (activeIndex: number) => {
      anchorLinks.forEach((link, index) =>
        link.classList.toggle('active', index === activeIndex)
      );
    };

    // Determine which sidebar item is active
    const onScroll = () => {
      const scrollPosition = document.documentElement.scrollTop;
      let activeIndex = 0;
      for (let index = 0; index < anchorTargets.length; index += 1) {
        const target = anchorTargets[index];
        if (
          target &&
          scrollPosition >= offset(target).top-133
        ) {
          activeIndex = index;
        }
      }
      setActiveAnchorLink(activeIndex);
    };

    registerScrollListener(onScroll);
    onScroll();
  });
};

export { initializeSubMenuLayout };
