import { gsap } from 'gsap';

export enum ScrollDirection {
  UP,
  DOWN,
  NONE,
}
type ScrollListener = (
  isScrollingToAnchor: boolean,
  scrollDirection: ScrollDirection
) => void;

let _isScrollingToAnchor = false;
let lastPageYOffset = 0;
const scrollListeners: ScrollListener[] = [];

export const scrollTo = (
  target: number | Element,
  offsetY: number = 0,
  isScrollingToAnchor: boolean = false
) => {
  _isScrollingToAnchor = isScrollingToAnchor;
  gsap.to(window, {
    duration: 0.3,
    scrollTo: {
      y: target,
      offsetY: offsetY,
    },
    onComplete: () => {
      // Requires a timeout because (sadly) there is one more scroll event after onComplete triggers
      setTimeout(() => {
        _isScrollingToAnchor = false;
      }, 50)
    },
  });
};

export const registerScrollListener = (callback: ScrollListener) => {
  scrollListeners.push(callback);
};

window.addEventListener(
  'scroll',
  (event) => {
    let scrollDirection = ScrollDirection.NONE;
    if (window.pageYOffset != lastPageYOffset) {
      scrollDirection =
        window.pageYOffset > lastPageYOffset
          ? ScrollDirection.DOWN
          : ScrollDirection.UP;
    }
    lastPageYOffset = window.pageYOffset;

    scrollListeners.forEach((callback) => {
      callback(_isScrollingToAnchor, scrollDirection);
    });
  },
  { passive: true }
);
