import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import throttle from 'lodash/throttle';
import get from 'lodash/get';

gsap.registerPlugin(ScrollTrigger);

// All in percentages
const SUPPORT_IMAGE_START_Y = 10;
const SUPPORT_IMAGE_START_Y_OFFSET = 5;

function initializeZoomGallery(zoomGalleryWrapper: HTMLDivElement) {

  const zoomGallerySticky = zoomGalleryWrapper.querySelector<HTMLImageElement>('.zoom-gallery-sticky');
  const zoomGallery = zoomGalleryWrapper.querySelector<HTMLImageElement>('.zoom-gallery');
  const imageGrid = zoomGalleryWrapper.querySelector<HTMLImageElement>('.grid');
  const mainImageWrapper = zoomGalleryWrapper.querySelector<HTMLImageElement>('.main-image-wrapper');
  const zoomTrigger = zoomGalleryWrapper.querySelector<HTMLImageElement>('.zoom-trigger');
  const mainImage = zoomGalleryWrapper.querySelector<HTMLImageElement>('.main-image');
  const mainImageTarget = zoomGalleryWrapper.querySelector<HTMLImageElement>('.main-image-target');

  if( zoomGallerySticky === null ||
      zoomGallery === null ||
      imageGrid === null ||
      mainImageWrapper === null ||
      zoomTrigger === null ||
      mainImage === null ||
      mainImageTarget === null)
      return;

  const getMainImageTargetOffset = () => {
    const zoomGalleryOffset = zoomGallery.getBoundingClientRect();
    const mainImageTargetOffset = mainImageTarget.getBoundingClientRect();

    return {
      top: mainImageTargetOffset.top - zoomGalleryOffset.top,
      left: mainImageTargetOffset.left - zoomGalleryOffset.left,
    }
  };

  const getZoomTriggerOffset = () => {
    const zoomGalleryOffset = zoomGallery.getBoundingClientRect();
    const zoomTriggerOffset = zoomTrigger.getBoundingClientRect();

    return {
      top: zoomTriggerOffset.top - zoomGalleryOffset.top,
      left: zoomTriggerOffset.left - zoomGalleryOffset.left,
    }
  };

  const updateZoomGalleryStickyOffset = () => {
    zoomGallerySticky.style.top = -get(getZoomTriggerOffset(),'top') + 'px';
  }



  /* Main image */
  const mainImageAnimation = gsap.fromTo(
    mainImage,
    {
      width: () => mainImageWrapper.clientWidth,
      height: () => mainImageWrapper.clientHeight,
      top: 0,
      left: 0,
    },
    {
      width: () => mainImageTarget.clientWidth,
      height: () => mainImageTarget.clientHeight,
      top: () => get(getMainImageTargetOffset(), 'top'), 
      left: () => get(getMainImageTargetOffset(), 'left'),
      ease: "power4",
    },
  );

  const mainImageScrollTrigger = ScrollTrigger.create({
    animation: mainImageAnimation,
    trigger: zoomGalleryWrapper,
    start: () => `3% top`,
    end: () => `80% bottom`,
    scrub: true,
  });

  /* Supporting images */
  const supportImages = zoomGallerySticky.querySelectorAll<HTMLImageElement>('.support-image');
  const supportImageAnimations:Array<any> = [];

  supportImages.forEach((_, index) => {
    const startPosY:string = (SUPPORT_IMAGE_START_Y + index*SUPPORT_IMAGE_START_Y_OFFSET) + "%";
    const endPosY:string = '90%';
    const imageSelector = `.support-image[data-index='${index}']`;

    const animation = gsap.fromTo(imageSelector, {
      transform: () => `translateZ(100vw)`,
    }, {
      transform: () => `translateZ(0vw)`,
      ease: `power${supportImages.length - 1 - index}.out`
    });

    const scrollTrigger = ScrollTrigger.create({
        trigger: zoomGalleryWrapper,
        start: () => `${startPosY} top`,
        end: () => `${endPosY} bottom`,
        scrub: true,
        animation: animation,
    });    
    supportImageAnimations.push(animation);
  });

  type TweenLiteToType = InstanceType<typeof supportImageAnimations[0]>;

  const throttledInvalidate = throttle(() => {
      ScrollTrigger.refresh();
      mainImageAnimation.invalidate();
      supportImageAnimations.forEach((animation:TweenLiteToType) => (
        animation.invalidate()
      ));
      
      ScrollTrigger.refresh();
      updateZoomGalleryStickyOffset();
    }, 250, {leading: false, trailing: true});

  // We want to recalculate our start and end position on resize
  window.addEventListener('resize', throttledInvalidate);
  updateZoomGalleryStickyOffset();
}

export function initializeHomeZoomGallery() {
  const zoomGalleryWrapper = document.querySelector<HTMLDivElement>('.page-home .zoom-gallery-wrapper');
  if (zoomGalleryWrapper) initializeZoomGallery(zoomGalleryWrapper);
}
