export const debounce = (func: Function, wait: number, immediate = false) => {
  let timeout: number = 0;

  return function() {
    const args = arguments;

    const later = function() {
      timeout = 0;

      if (!immediate) {
        func.apply(null, args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);

    if (callNow) {
      func.apply(null, args);
    }
  };
};