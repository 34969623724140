import { offset } from '../../utils/offset';

export default class Slider {
  container: HTMLDivElement;
  labelForValue: (value: number) => string;
  onChange: (value: number) => void;

  private _value: number = 0;
  private valueLabel: HTMLDivElement;
  private handle: HTMLDivElement;
  private handleValueLabel: HTMLSpanElement;
  private dragging: boolean;

  constructor(
    container: HTMLDivElement,
    initialValue: number,
    labelForValue: (value: number) => string,
    onChange: (value: number) => void
  ) {
    this.container = container;
    this.labelForValue = labelForValue;
    this.onChange = onChange;

    this.valueLabel = container.querySelector('.value-label') as HTMLDivElement;
    this.handle = container.querySelector('.handle') as HTMLDivElement;
    this.handleValueLabel = this.handle.querySelector(
      'span'
    ) as HTMLSpanElement;
    this.dragging = false;

    this.value = initialValue;

    this.onHandleInteractionStart = this.onHandleInteractionStart.bind(this);
    this.onDocumentInteractionEnd = this.onDocumentInteractionEnd.bind(this);
    this.onDocumentInteractionMove = this.onDocumentInteractionMove.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);

    this.registerEventHandlers();
  }

  get value(): number {
    return this._value;
  }

  set value(val: number) {
    this._value = val;
    this.updateLabel(val);
    this.updateHandle(val);
    this.onChange(this.value);
  }

  updateHandle(value: number) {
    this.handle.style.left = `${
      (value as any) * (this.container.clientWidth - this.handle.clientWidth)
    }px`;
  }

  updateLabel(value: number) {
    const label = this.labelForValue(value);
    this.handleValueLabel.innerText = label;
    this.valueLabel.innerText = label;
  }

  registerEventHandlers() {
    this.handle.addEventListener('mousedown', this.onHandleInteractionStart);
    this.handle.addEventListener('touchstart', this.onHandleInteractionStart);
    document.addEventListener('mouseup', this.onDocumentInteractionEnd);
    document.addEventListener('touchend', this.onDocumentInteractionEnd);
    document.addEventListener('mousemove', this.onDocumentInteractionMove);
    document.addEventListener('touchmove', this.onDocumentInteractionMove);
    window.addEventListener('resize', this.onWindowResize);
  }

  onHandleInteractionStart(e: any) {
    e.preventDefault();
    this.dragging = true;
  }

  onDocumentInteractionEnd(e: any) {
    this.dragging = false;
  }

  onDocumentInteractionMove(e: any) {
    if (this.dragging) {
      const pageX = typeof e.touches != "undefined" ? e.touches[0].pageX : e.pageX;
      const containerOffset = offset(this.container);
      // Adjust offset and container with handle size to avoid
      // having to drag the cursor all the way to the edges.
      // Using height instead of width because the changes in width causes jitter.
      const containerRelativeCursurOffset =
        pageX - containerOffset.left - this.handle.clientHeight / 2;
      const containerWidth =
        this.container.clientWidth - this.handle.clientHeight;
      const clippedX = Math.max(
        0,
        Math.min(containerWidth - 1, containerRelativeCursurOffset)
      );
      const relativePosition = clippedX / containerWidth;
      this.value = relativePosition;
    }
  }

  onWindowResize() {
    // Update handle position by triggering value update
    this.updateHandle(this.value);
  }
}