function initializeTabbar(tabbar: HTMLDivElement) {
  const tabs = tabbar.querySelectorAll('.tab');

  const onTabClick = (event:Event) => {
    tabs.forEach(tab => tab.classList.remove('selected'));
    const newSelectedTab = event.target as HTMLAnchorElement;
    newSelectedTab.classList.add('selected');
  }

  tabs.forEach(tab => (
    tab.addEventListener('click', onTabClick)
  ));
}

export function initializeTabbars() {
  const tabbars: NodeListOf<HTMLDivElement> = document.querySelectorAll(
    '.tabbar'
  );
  tabbars.forEach(initializeTabbar);
}
