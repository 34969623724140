import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import breakpoints from '../constants/breakpoints';
import {
  initUSPAnimation1,
  initUSPAnimation2,
  initUSPAnimation3,
} from './usp_animations';

gsap.registerPlugin(ScrollTrigger);

function initializeUSPSection(uspSection: HTMLDivElement) {
  const cards: NodeListOf<HTMLDivElement> = uspSection.querySelectorAll(
    '.js-usp-card'
  );

  cards.forEach((card, index) => {
    card.style.zIndex = (cards.length - index).toString();
  });

  const illustration_1: SVGElement | null = cards[0].querySelector('svg');
  if (illustration_1 !== null) {
    ScrollTrigger.create({
      animation: initUSPAnimation1(illustration_1),
      trigger: uspSection,
      start: 'top center',
    });
  }

  const illustration_2: SVGElement | null = cards[1].querySelector('svg');
  if (illustration_2 !== null) {
    ScrollTrigger.create({
      animation: initUSPAnimation2(illustration_2),
      trigger: uspSection,
      start: '50% top',
    });
  }

  const illustration_3: SVGElement | null = cards[2].querySelector('svg');
  if (illustration_3 !== null) {
    ScrollTrigger.create({
      animation: initUSPAnimation3(illustration_3),
      trigger: uspSection,
      start: '150% top',
    });
  }

  const tl = gsap.timeline();
  tl.to(cards[0], { yPercent: -100, ease: 'none' }).to(cards[1], {
    yPercent: -100,
    ease: 'none',
  });

  ScrollTrigger.create({
    animation: tl,
    trigger: uspSection,
    start: 'top top',
    end: '+200%',
    scrub: true,
    pin: true,
  });
}

export function initializeUSPSections() {
  // Note: curently there are two distinct USP sections. This script only applies to the desktop version.
  const uspSections: NodeListOf<HTMLDivElement> = document.querySelectorAll<HTMLDivElement>(
    '.js-usp-component-desktop'
  );
  const breakpoint = `(min-width: ${breakpoints.tablet}px)`;

  ScrollTrigger.matchMedia({
    [breakpoint]: () => {
      uspSections.forEach(
        (uspSection) => uspSection && initializeUSPSection(uspSection)
      );
    },
  });
}
