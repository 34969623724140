import { logarithmicRange } from '../../utils/logarithmic_range';
import get from 'lodash/get';
import Slider from './slider';
import { LOCATIONS, ILocation, DEFAULT_LOCATION_ID, getLocationById } from '../../data/pricing';
import { getCookie } from '../../utils/get_cookie';


// This is obviously not the exact amount of days in an average month,
// but a nice round number to calculate price per employee per month (assuming the other values play along nicely too)
const DAYS_PER_MONTH = 30;

const SQUARE_METERS_PER_EMPLOYEE = 10;

const EMPLOYEE_STEPS = logarithmicRange(5, 6000, 1000);
const OFFICE_AREA_STEPS = logarithmicRange(150, 60000, 1000);

// TODO: Consider setting these to actual numbers, not normalized slider values
// This would require the conversation from slider value to actual value be reversible, which it isn't right now
const DEFAULT_EMPLOYEE_SLIDER_VALUE = 0.125;
const DEFAULT_AREA_SLIDER_VALUE = 0;


function initPriceCalculator(container: HTMLDivElement) {

  /* Get DOM elements */

  const peopleSliderElm = container.querySelector('.slider.people') as HTMLDivElement;
  const officeSizeSliderElm = container.querySelector('.slider.office-size') as HTMLDivElement;

  // Select element in currency selector
  const locationSelect = container.querySelector('.location-selector select') as HTMLSelectElement;
  // Span to display selected currency
  const locationButtonLabel = container.querySelector('.location-selector button span') as HTMLSpanElement;
  // Element to insert price result
  const resultValueEl = container.querySelector('.result .value') as HTMLDivElement;
  // Element under price result with a sidenote
  const priceNoteEl = container.querySelector('.price-result .price-note') as HTMLDivElement;


  /* Current slider values */

  // TODO: This start value attribute is the value of the slider, not of the amount of people.
  // Due to the way the logarithmic scale works, it'll need some work to be able to go from
  // output value (number of people) to input value (normalized slider value).
  let employeeSliderValue = (peopleSliderElm.getAttribute('data-start-value') || DEFAULT_EMPLOYEE_SLIDER_VALUE) as number;
  let areaSliderValue = DEFAULT_AREA_SLIDER_VALUE;
  const preferredLocation = document.documentElement.getAttribute('data-preferred-location');
  let currentLocation = preferredLocation || DEFAULT_LOCATION_ID;


  /* Connect components */

  // Slider range is [0-1]
  // Displayed value is derived from EMPLOYEE_STEPS
  const employeeSlider = new Slider(
    peopleSliderElm,
    (employeeSliderValue as number),
    (value) => {
      return `${getStepValueFromSliderValue(value, EMPLOYEE_STEPS)}`;
    },
    (value) => {
      employeeSliderValue = value;
      mirrorEmployeesToArea();
      update();
    }
  );

  // Slider range is [0-1]
  // Displayed value is derived from OFFICE_AREA_STEPS
  const areaSlider = new Slider(
    officeSizeSliderElm,
    areaSliderValue,
    (value) => {
      return `${getStepValueFromSliderValue(value, OFFICE_AREA_STEPS)}`;
    },
    (value) => {
      areaSliderValue = value;
      update();
    }
  );

  // Add currency options to selector
  if(locationSelect && locationButtonLabel) {
    LOCATIONS.forEach(location => {
      const locationOption = document.createElement(`option`);
      locationOption.setAttribute('value', location.id);
      locationOption.innerHTML = getSelectTextForLocation(location);
      if(location.id === currentLocation) locationOption.setAttribute('selected', 'true');
      locationSelect.appendChild(locationOption);
    });

    locationSelect.addEventListener('change', event => {
      const value:string = event && get(event, 'target.value');
      updateSessionLocation(value);
    });
  }

  const onHTMLMutate = (mutationsList: Array<MutationRecord>) => {
    mutationsList.forEach((mutation: MutationRecord) => {
      if(mutation.attributeName === "data-preferred-location") {
        update();
      }
    })
  }

  const observer = new MutationObserver(onHTMLMutate);
  observer.observe(document.documentElement, {attributes: true});


  /* Update methods */

  // Request an update of the preferred location and change data attribute on success
  function updateSessionLocation(location:string) {
    currentLocation = location;

    const csrftoken:string|null = getCookie('csrftoken');
    if(csrftoken) {
      const request = new Request(
        '/set-preferred-location/',
        { headers: {'X-CSRFToken': csrftoken} }
      );
      fetch(request, {
        method: 'POST',
        mode: 'same-origin',
        credentials: 'include',
        body: JSON.stringify({
          preferred_location: currentLocation,
        }),
      })
      .then(response => response.json())
      .then(data => {
        // Update the data attribute on <html> to inform any mutation listeners
        // NOTE: Once we have pages in each language, we'd do a page reload and get do away with
        // these front-end methods like mutation listeners
        document.documentElement.setAttribute('data-preferred-location', data.preferred_location);
      })
    };
  }

  function update() {
    const area = getStepValueFromSliderValue(areaSliderValue, OFFICE_AREA_STEPS);
    const location:ILocation|undefined = getLocationById(currentLocation);

    if(location) {
      locationButtonLabel.innerText = location.currency.label;
      const {pricePerArea, signifier} = location.currency;
      const pricePerMonth = area * pricePerArea ;
      resultValueEl.innerText = `${signifier}${pricePerMonth}`;

      // Note: The latest copy has no prices and thus no longer needs updating.
      // Keep the code here for the not unlikely case we'll switch back again soon.
      //const pricePerEmployeePerMonth:number = Math.round((SQUARE_METERS_PER_EMPLOYEE * pricePerArea) * 100) / 100;
      //const pricePerEmployeePerDay:number =  Math.round((SQUARE_METERS_PER_EMPLOYEE * pricePerArea / DAYS_PER_MONTH) * 100) / 100;
      //priceNoteEl.innerText = `* ${signifier}${pricePerEmployeePerDay} per co-worker a day converts to ${signifier}${pricePerEmployeePerMonth} per ${SQUARE_METERS_PER_EMPLOYEE} sqm a month.`
    }
  }

  // Matches area to employee count; used whenever employee count is set by user
  function mirrorEmployeesToArea() {
    if (!areaSlider) return;
    const targetOfficeArea = getStepValueFromSliderValue(employeeSliderValue, EMPLOYEE_STEPS) * SQUARE_METERS_PER_EMPLOYEE;
    const index = OFFICE_AREA_STEPS.findIndex(
      (value) => value >= targetOfficeArea
    );
    const step = index == -1 ? OFFICE_AREA_STEPS.length : index;
    areaSliderValue = step / OFFICE_AREA_STEPS.length;
    areaSlider.value = areaSliderValue;
  }

  // Convert the normalized slider value to actual displayed number
  function getStepValueFromSliderValue<T>(value: number, items: T[]): T {
    return items[Math.ceil((items.length - 1) * value)];
  }

  function getSelectTextForLocation(location:ILocation):string {
    return `${location.label} (${location.currency.label})`;
  }

  // First update
  mirrorEmployeesToArea();
  update();  
}

export function initializePriceCalculators() {
  const containers = document.querySelectorAll('.block-price-calculator, .price-calculator') as NodeListOf<HTMLDivElement>;
  containers.forEach(initPriceCalculator);
}
